import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const QuoteMail = props => {
  const { children, icon } = props

  return (
    <Wrapper>
      <Overlay />
      <Body>{children}</Body>
      <Footer>
        <Img fluid={icon} />
      </Footer>
    </Wrapper>
  )
}

QuoteMail.propTypes = {
  icon: PropTypes.object.isRequired,
}

export default QuoteMail

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 30px;
  font-family: Caveat;
  background: white;
  font-size: 18px;
  letter-spacing: 1px;
  color: #8c8c8e;
  box-shadow: 10px 10px 18px rgba(0, 0, 0, 0.05);
  transform: matrix(1, 0, 0, 1, 0, 0);

  ${customMedia.greaterThan('desktop')`
    font-size: 22x;
  `};
`

const Overlay = styled.div`
  position: absolute;
  background: white;
  box-shadow: 10px 10px 18px rgba(0, 0, 0, 0.05);
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: -1;
  width: 100%;
  height: 100%;
  bottom: 7px;
  right: 7px;
`

const Body = styled.div`
  width: 100%;
`
const Footer = styled.div`
  width: 100px;
  margin-left: auto;
`
