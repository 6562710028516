import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import Button from '../components/share/button'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FeatureBox from '../components/share/featureBox'
import FooterBox from '../components/share/footerBox'
import GreyBackground from '../components/share/greyBackground'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import Quote from '../components/share/quote'
import QuoteMail from '../components/support/quoteMail'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "share/supportBackground.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerPhone: file(relativePath: { eq: "support/headerPhone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    Belle: file(relativePath: { eq: "brandLogos/belleProperty.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    LJHooker: file(relativePath: { eq: "brandLogos/ljHooker.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    TheAgency: file(relativePath: { eq: "brandLogos/theAgency.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    Morton: file(relativePath: { eq: "brandLogos/morton.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    comments: file(relativePath: { eq: "support/comments.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    headset: file(relativePath: { eq: "support/headset.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    university: file(relativePath: { eq: "support/university.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    happyCustomers: file(relativePath: { eq: "support/happyCustomers.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    happyCustomers2: file(relativePath: { eq: "support/happyCustomers2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    australia: file(relativePath: { eq: "support/australia.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    johnCunningham: file(
      relativePath: { eq: "brandAgentIcon/john_cunningham.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    glasses: file(relativePath: { eq: "share/glasses.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rocket: file(relativePath: { eq: "support/rocket.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class SupportPage extends Component {
  _scrollToSection = title => {
    this[title].scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.5"
                skew
              >
                <ContentWrapper style={{ marginTop: 50, flexWrap: 'nowrap' }}>
                  <HeaderTitle>
                    Immediate, reliable support
                  </HeaderTitle>

                  <HeaderBody>
                    You don’t need just an account manager, you need{' '}
                    <strong>a team that’s got your back.</strong> A single team
                    member might leave, or chuck a sickie. Our support platform
                    offers our entire team across email, phone and messaging.{' '}
                    <strong>Completely transparent</strong> support history, no
                    more emails going to absent staff.
                    <br />
                    <br />
                    Support for professional agencies, just like yours.
                  </HeaderBody>

                  <HeaderLink
                    onClick={() => this._scrollToSection('firstSection')}
                  >
                    Decentralised support, we’re all helping together
                    <Icon icon={['fal', 'arrow-down']} />
                  </HeaderLink>

                  <Button
                    text="Try it for yourself"
                    onClick={() => this._scrollToSection('requestDemo')}
                  />
                </ContentWrapper>

                <HeaderPhoneImg
                  fluid={data.headerPhone.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>
              <ContentWrapper style={{ alignItems: 'center', marginTop: 120 }}>
                <BannerH4>
                  Don’t take our word for it. <br />
                  <strong>Hear it from our customers.</strong>
                </BannerH4>
              </ContentWrapper>

              <ContentWrapper style={{ marginBottom: 0, zIndex: 2 }}>
                <QuoteMail icon={data.Belle.childImageSharp.fluid}>
                  <Paragraph fontFamily="Caveat">
                    Hey <BlueSpan>Realhub</BlueSpan>
                  </Paragraph>

                  <Paragraph fontFamily="Caveat">
                    Seriously <strong>you guys rock! </strong>
                    And we are so happy to have come across... As they say, if
                    only we had done this earlier. :)
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    Everyone including myself has been{' '}
                    <strong>over the moon</strong> how smoothly everything has
                    gone, your <strong>communication</strong>, ease and
                    flexibility and just getting to know everyone up to this
                    point has been amazing.
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    You guys know how to make us feel calm when we hit the go
                    button. I must say this as it’s{' '}
                    <strong>one of your best team qualities</strong> -
                    everything felt <strong>easy and under control.</strong>
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    Thank you, <br />
                    Looking forward to many good years ahead!
                  </Paragraph>
                </QuoteMail>
              </ContentWrapper>

              <GreyBackground skew style={{ margin: '20px 0' }}>
                <ContentWrapper direction="row">
                  <FeatureBox
                    imgLength="35px"
                    image={data.university.childImageSharp.fluid}
                    title="Knowledge Base"
                    subTitle="Answers to all the most common questions, available 24/7"
                    content="Our knowledge base means you can get answers to questions any time of day."
                  />
                  <FeatureBox
                    imgLength="35px"
                    image={data.comments.childImageSharp.fluid}
                    title="Email/Chat Support"
                    subTitle="Quick, immediate solutions to everyday problems. No Hassles."
                    content="Experts in templates, products, providers and technology, all within reach of your fingertips."
                  />
                  <FeatureBox
                    imgLength="35px"
                    image={data.headset.childImageSharp.fluid}
                    title="Phone Support"
                    subTitle="Premium phone support for instant help within business hours."
                    content="Available on the enterprise level our phone support has solutions in seconds. So you can move on with the rest of your day."
                  />
                </ContentWrapper>
              </GreyBackground>

              <ContentWrapper>
                <QuoteMail icon={data.LJHooker.childImageSharp.fluid}>
                  <Paragraph fontFamily="Caveat">
                    Hi <BlueSpan>Realhub</BlueSpan>
                  </Paragraph>

                  <Paragraph fontFamily="Caveat">
                    Just a quick message to thank you and your team for your
                    help this week.
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    It was a <strong>huge job </strong>
                    to get all <strong>9 of our offices</strong> up and running
                    and your team did an amazing job keeping everyone calm and
                    moving forward to meet the deadlines.
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    My email inbox shows that you guys answered{' '}
                    <strong>138 emails from me in 3 days </strong>
                    which is huge. Particularly when I think the{' '}
                    <strong>
                      average response time was about 10 minutes tops.
                    </strong>
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    Regards, <br />
                    Lisa
                  </Paragraph>
                </QuoteMail>
              </ContentWrapper>

              <ContentWrapper>
                <Img
                  fluid={data.happyCustomers.childImageSharp.fluid}
                  style={{ width: '100%', height: '100%' }}
                />
              </ContentWrapper>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4 style={{ width: '60%', margin: '0 auto 15px auto' }}>
                  You’ll ask <strong>less questions</strong> and receive{' '}
                  <strong>faster answers</strong>, with Realhub.
                </BannerH4>
                <BannerH6>
                  A platform built to negate the need for support, coupled with
                  the industries best support.
                </BannerH6>
              </ContentWrapper>

              <ContentWrapper>
                <QuoteMail icon={data.TheAgency.childImageSharp.fluid}>
                  <Paragraph fontFamily="Caveat">
                    Hey <BlueSpan>Realhub</BlueSpan>
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    Thanks so much for your help!{' '}
                    <strong>
                      I can’t believe it’s been this easy a transition
                    </strong>{' '}
                    and I’m sure we (ok I) hound you guys a lot. Wanted to say I
                    appreciate <strong> all your help </strong>
                    all the time, that’s all!
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    Kindest Regards, <br />
                    Jenny
                  </Paragraph>
                </QuoteMail>
              </ContentWrapper>
              <ContentWrapper>
                <Img
                  fluid={data.happyCustomers2.childImageSharp.fluid}
                  style={{ width: '100%', height: '100%' }}
                />
              </ContentWrapper>
              <ContentWrapper>
                <QuoteMail icon={data.Morton.childImageSharp.fluid}>
                  <Paragraph fontFamily="Caveat">
                    Hey <BlueSpan>Realhub</BlueSpan>
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    The system was <strong>super easy to use </strong>
                    once we got all the templates right :)
                  </Paragraph>
                  <Paragraph fontFamily="Caveat">
                    <strong>Really fast responses</strong> from you and your
                    team <strong> which we LOVE</strong> here at Morton!
                  </Paragraph>

                  <Paragraph fontFamily="Caveat">
                    Thanks Guys, <br />
                    Paige
                  </Paragraph>
                </QuoteMail>
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.australia.childImageSharp.fluid}
                  title="Experienced support that you can count on to get the job done."
                  reverse
                  content={
                    <Fragment>
                      <Paragraph>
                        When it comes to support, it’s easier to get your point
                        across when speaking to us directly. That is why with us
                        that all our phone, email and chat support is provided
                        100% here in Australia.
                      </Paragraph>

                      <Paragraph>
                        Highly trained across the entire platform, with niche
                        experts providing support with templates, publications,
                        providers and other services, you’ll always have access
                        to support that can get the job done.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <Quote
                  agentImage={data.johnCunningham.childImageSharp.fluid}
                  desc={
                    <Paragraph fontFamily="Caveat" style={{ fontSize: 20 }}>
                      “It was a very big decision, we'd been with a previous
                      supplier for over 20 years. But what was presented to us
                      was so exciting and refreshing, we couldn't ignore it.
                      <br />
                      <br />
                      The delivery has been above expectation.
                      <br />
                      <br />
                      There's nothing that's too hard.”
                    </Paragraph>
                  }
                  agencyName="Cunninghams"
                  title="Founder & Director"
                  agentName="John Cunningham"
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.glasses.childImageSharp.fluid}
                  title="Industry leading design and usability"
                  link="https://blog.realhub.com.au/"
                  linkText="Read more on our blog"
                  content={
                    <Fragment>
                      <Paragraph>
                        We focus on using support and communication technology
                        that assists us in helping your team. The software to
                        power our company leads the market in increasing
                        efficiency and providing accountability.
                      </Paragraph>

                      <Paragraph>
                        We build out solutions for our staff so their focus is
                        on helping you complete tasks quickly and efficiently.
                        When issues re-occur frequently and add to our support
                        volume we don’t add people, we develop solutions.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.rocket.childImageSharp.fluid}
                  title="We’re not off in space, we’re here on the ground, solving your problems."
                  content={
                    <Fragment>
                      <Paragraph
                        fontFamily="Caveat"
                        style={{ fontSize: 24, color: '#8C8C8E' }}
                      >
                        Avg. 3,000 tickets solved per week <br />
                        <br />
                        74% Replies under 1 Hour
                        <br />
                        <br />
                        95% Replies in the same Day
                        <br />
                        <br />
                        Support SLAs available for Enterprise
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper
                style={{ alignItems: 'center' }}
                contentRef={el => (this.requestDemo = el)}
              >
                <BannerH4>
                  All of our products are{' '}
                  <strong>backed by the same support </strong>
                  team, across your entire marketing efforts.
                  <br />
                  <strong>We’re there for you.</strong>
                </BannerH4>
                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <SpeakToTeam
                  uid="WpgxlK"
                  avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
                />
              </ContentWrapper>

              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>

              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>

              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default SupportPage

const HeaderTitle = styled.h1`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    max-width: 750px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 2;
  margin-bottom: 30px;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    width: 450px;
  `};
`

const HeaderPhoneImg = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  bottom: -90px;
  right: 50px;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 250px;
    bottom: -35px;
    right: 0px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 450px;
    height: 350px;
    bottom: -100px;
    right: -40px;
  `};
`

const HeaderLink = styled.a`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  width: 100%;

  ${customMedia.greaterThan('desktop')`
    margin-bottom: 10px;
    font-size: 18px;
  `};
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-left: 5px;
`

const Paragraph = styled.p`
  font-family: ${props => props.fontFamily || 'Open Sans'};
  margin-bottom: 30px;
`

const BlueSpan = styled.span`
  color: #02b7e2;
`
